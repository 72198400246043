import { throttle } from 'lodash';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import { SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import API from 'app/API';
import TextField from 'app/ui-kit/components/TextField';
import IconEyeClosed from 'app/ui-kit/icons/EyeClosed.svg';
import IconEye from 'app/ui-kit/icons/EyeOpen.svg';
import { logger } from 'services/logger';

import useStyles, { BLANK_COLOR, COLORS } from './styles';

interface Props {
    onChange: (value: string) => void;
    onSubmit?: () => void;
    skipValidation?: boolean;
    className?: string;
}

const Password: React.FC<Props> = ({ onChange, onSubmit, skipValidation = false, className }) => {
    const { classes, cx } = useStyles();

    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [color, setColor] = useState('');

    const [strength, setStrength] = useState(0);
    const [showPass, toggleShowPass] = useReducer((state) => !state, false);

    const handleEnterKey = useCallback((event: React.KeyboardEvent) => event.code === 'Enter' && onSubmit?.(), [
        onSubmit
    ]);

    const checkPassStrength = useCallback(async (password: string) => {
        try {
            const { data } = await API.fetch(API.CHECK_PASSWORD, { password });
            const { improvements, strenght: strength } = data;
            const [error] = Object.values(improvements);
            setStrength(strength);
            setError(strength > 0.5 ? '' : String(error));
            setColor(COLORS.find(({ ln }) => strength >= ln)?.color ?? '');
        } catch (error) {
            logger.error(error);
        }
    }, []);

    const checkPassStrengthThrottled = useMemo(() => throttle(checkPassStrength, 300), [checkPassStrength]);
    useEffect(() => () => checkPassStrengthThrottled.cancel(), [checkPassStrengthThrottled]);

    const handleChange = useCallback(
        (event) => {
            const value = event.target.value.trim();
            setPassword(value);
            onChange(value);
            if (value) {
                if (!skipValidation) {
                    checkPassStrengthThrottled(value);
                }
            } else {
                setColor('');
                setStrength(0);
                setError('');
            }
        },
        [checkPassStrengthThrottled, onChange, skipValidation]
    );

    return (
        <div className={cx(classes.root, className)}>
            <TextField
                fullWidth
                name="password"
                label="Password"
                type={showPass ? 'text' : 'password'}
                error={!!error}
                helperText={error}
                onChange={handleChange}
                onKeyDown={handleEnterKey}
                value={password}
                InputProps={{
                    className: cx(classes.input, className),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={toggleShowPass} edge="end" size="small">
                                {<SvgIcon component={showPass ? IconEye : IconEyeClosed} />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            {skipValidation ? null : (
                <div className={classes.strength}>
                    <div className={classes.strengthPoint} style={{ background: strength > 0 ? color : BLANK_COLOR }} />
                    <div
                        className={classes.strengthPoint}
                        style={{ background: strength > 0.3 ? color : BLANK_COLOR }}
                    />
                    <div
                        className={classes.strengthPoint}
                        style={{ background: strength > 0.6 ? color : BLANK_COLOR }}
                    />
                    <div
                        className={classes.strengthPoint}
                        style={{ background: strength > 0.8 ? color : BLANK_COLOR }}
                    />
                </div>
            )}
        </div>
    );
};

export default Password;
